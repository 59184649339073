import http from "../utils/request"
// 轮播列表
export const getBanner = () => http.get('/server/banner/list')

// 获取产品
export const getChangp = () => http.get('/server/product/list')
// 产品详情

export const  changpDetail= (params) => http.get('/server/product/'+params)

// 获取二维码等
export const getCommunity = () => http.get('/server/community/list')

// 获取最新动态
export const getNews= (params) => http.get('/server/news/list',params)

// 关于
export const getLxd = () => http.get('/server/lxd/list')
