var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('Heard',{on:{"menClick":_vm.menClick}}),_c('div',{staticClass:"men"},[_c('div',{staticClass:"pad"},_vm._l((_vm.list),function(item){return _c('div',{key:item.tit,staticClass:"pa-item",class:(_vm.$route.path==item.path?'avtive':''),on:{"click":function($event){return _vm.toPath(item)}}},[_vm._v(_vm._s(item.tit))])}),0)]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_c('bottom')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-1"},[_c('div',{staticClass:"flex",staticStyle:{"marginLeft":"384px","height":"100%"}},[_c('img',{staticClass:"bb1",attrs:{"src":require("../../assets/img/bb1.png"),"alt":""}}),_c('div',{staticClass:"text1"},[_c('div',[_vm._v("露晰得中国 | 上海有康医疗器械有限公司")]),_c('br'),_c('div',[_vm._v("诞生于2009年的有康，倚借清晰的战略规划、鲜明的学术路径和专业的推广管理，")]),_c('div',[_vm._v("业已成为在中国眼视光领域拥有卓越口碑和赞誉的知名品牌。")]),_c('div',[_vm._v("这是一家从诞生之日起就思考并明确了使命、价值观和原则的公司，")]),_c('div',[_vm._v("这也是有康持续经年一路飞扬的核心动力！")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-2"},[_c('img',{staticClass:"bb2",attrs:{"src":require("../../assets/img/bb2.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-3"},[_c('div',{staticClass:"f28",staticStyle:{"margin":"52px auto 28px"}},[_vm._v("我们的使命：有康为什么存在？")]),_c('div',{staticClass:"f16"},[_vm._v("我们为了提高近视患者的生活品质而存在，")]),_c('div',{staticClass:"f16"},[_vm._v("我们向广大的近视患者提供优质的产品和专业的服务，点亮他/她们的世界，")]),_c('div',{staticClass:"f16"},[_vm._v("从而提升生活品质，我们也因此而获得价值实现！")]),_c('img',{staticClass:"bb3",attrs:{"src":require("../../assets/img/bb3.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-4"},[_c('div',{staticClass:"f28 white",staticStyle:{"marginBottom":"16px"}},[_vm._v("我们的价值观：是什么让我们汇聚在有康")]),_c('div',{staticClass:"f18 white",staticStyle:{"marginBottom":"27px"}},[_vm._v("诚实正直、以人为本、激情创新、共同发展")]),_c('div',{staticClass:"f18 white"},[_vm._v("人品永远是我们的第一关注点，诚实正直是有康人的品质共性。 我们崇尚“人人生而平等”，充分尊重彼此，诚挚善待自己及所有人。")]),_c('div',{staticClass:"f18 white"},[_vm._v("我们拒绝平庸，永远激情面对工作与生活，用创新把一切变得更加有趣。 在有康，“我们”的含义包括客户、供应商、同仁、伙伴以及家人。")]),_c('div',{staticClass:"f18 white"},[_vm._v("共同发展是我们汇聚在一起的动因")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-5"},[_c('img',{staticClass:"bb4",attrs:{"src":require("../../assets/img/bb4.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-6"},[_c('div',{staticClass:"f28 white",staticStyle:{"marginBottom":"28px"}},[_vm._v("我们的原则：我们的所有行为必须遵从的标准！")]),_c('div',{staticClass:"f18 white"},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("质量是我们的生命线")]),_vm._v(" | 质量和品质是一切的前提，这是我们的生命线！")]),_c('div',{staticClass:"f18 white"},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("健康生活，快乐工作")]),_vm._v(" | 唯健康，才幸福；唯快乐，才有创造。这是有康文化，也是所有制度之本。")]),_c('div',{staticClass:"f18 white"},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("简捷")]),_vm._v(" | 简单快捷，才能低耗高效。我们以此来评价和审视我们的经营管理。")]),_c('div',{staticClass:"f18 white"},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("尊重差异与个性")]),_vm._v(" | 我们追求价值共鸣，但充分尊重和包容差异与个性，这让我们多姿多彩！")]),_c('img',{staticClass:"bb5",attrs:{"src":require("../../assets/img/bb5.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-11"},[_c('div',{staticClass:"le-con"},[_c('div',{staticClass:"f28 width70",staticStyle:{"margin-top":"120px","marginBottom":"28px"}},[_vm._v("以品牌为己任")]),_c('div',{staticClass:"f16 width70",staticStyle:{"line-height":"28px"}},[_vm._v("自创建起，一直秉承公司与产品的核心理念，努力践行；不仅践行“品牌”理念于商品，更是践行于员工、合作伙伴以及行业！")])]),_c('div',{staticClass:"ri-img"},[_c('img',{staticClass:"bb11",attrs:{"src":require("../../assets/img/bb11.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-7"},[_c('div',{staticClass:"f28",staticStyle:{"marginBottom":"21px"}},[_vm._v("有康文化——“快乐工作，健康生活”")]),_c('div',{staticClass:"f18"},[_vm._v(" “快乐工作，健康生活”，这是有康的核心文化！")]),_c('div',{staticClass:"f18"},[_vm._v(" 这8个字，是所有制度的设计原点，也是解决所有问题的最终仲裁依据！")]),_c('div',{staticClass:"f18"},[_vm._v(" 我们坚信：唯有快乐，方有创新；唯有健康，方有未来；唯有这8字，方有惬意职场人生！")]),_c('img',{staticClass:"bb6",attrs:{"src":require("../../assets/img/bb6.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-8"},[_c('img',{staticClass:"bb7",attrs:{"src":require("../../assets/img/bb7.jpg"),"alt":""}}),_c('div',{staticClass:"cont"},[_c('div',{staticClass:"f28",staticStyle:{"marginBottom":"23px"}},[_vm._v("有康DNA")]),_c('div',{staticClass:"f18"},[_vm._v("以人为本")]),_c('div',{staticClass:"f18"},[_vm._v("我们崇尚源自内心的尊重，于同仁、于同道、于合作商，皆如此；")]),_c('div',{staticClass:"f18"},[_vm._v("以专业为本")]),_c('div',{staticClass:"f18"},[_vm._v("不专业，无以立足，这是一个从诞生开始就没有一周停止过专业培训且拥有特色知识平台的公司；")]),_c('div',{staticClass:"f18"},[_vm._v("以创新为本")]),_c('div',{staticClass:"f18"},[_vm._v("我们厌倦重复，2.0、3.0版本是我们口头禅，所以我们引领了行业的诸多创新；")]),_c('div',{staticClass:"f18"},[_vm._v("以文化为本")]),_c('div',{staticClass:"f18"},[_vm._v("文化是我们的核心纽带，不仅仅是所有制度、规范的源泉，更是我们共鸣思想的提炼；")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-9"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"f28 white",staticStyle:{"marginBottom":"27px"}},[_vm._v("有康社会责任与担当")]),_c('div',{staticClass:"f18 white"},[_vm._v("有康，从创建起就已明确自己的使命：我们是为了提高近视患者的生活品质而存在!")]),_c('div',{staticClass:"f18 white"},[_vm._v("使命的驱使，让我们从患者、行业、社稷等多个维度和多个层面来综合思考，并为此而奋斗")]),_c('div',{staticClass:"f18 white"},[_vm._v("所以，这里会有“中国眼视光英才培育计划——明日之星”")]),_c('div',{staticClass:"f18 white"},[_vm._v("“雅安公益行动”、“家关怀”等多层级、多对象的社会责任与担当!")])]),_c('div',{staticClass:"right"},[_c('img',{staticClass:"bb8",attrs:{"src":require("../../assets/img/bb8.jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-10"},[_c('div',{staticClass:"f28",staticStyle:{"margin-bottom":"28px"}},[_vm._v("有康愿景")]),_c('div',{staticClass:"f16"},[_vm._v("有康，矢志于助推和引领中国眼视光的发展，成为一家受人尊敬的公司！")]),_c('div',{staticClass:"bb9"},[_c('img',{attrs:{"src":require("../../assets/img/bb9.jpg"),"alt":""}})])])
}]

export { render, staticRenderFns }