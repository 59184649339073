<template>
  <div class="home width" >
        <!-- 头部 -->
        <Heard @menClick="menClick"/>
        <!-- 轮播 -->
        <div  v-if="obj.carouselAtlas&&obj.carouselAtlas.length">
            <el-carousel class="carousel"   :height="bannerHeight + 'px'"    arrow="always" indicator-position="none">
          <el-carousel-item  v-for="item in obj.carouselAtlas"  :key="item">
            <img :src="item" alt=""  @load="imgload" ref="bannerHeight"  >
          </el-carousel-item>
        </el-carousel>
        </div>
        <div class="info-content" v-html="obj.content"></div>
        <div class="to" v-if="(obj.first!=null)" @click="getDetail(obj.first.id)">上一篇：<span>{{obj.first.title}}</span></div>
        <div class="to" v-if="(obj.last!=null)" @click="getDetail(obj.last.id)">下一篇：<span>{{obj.last.title}}</span></div>
        <bottom />
  </div>
</template>

<script>
import {changpDetail} from '../../api/index'
import Heard from '../../components/heard.vue'
import Bottom from '@/components/bottom.vue'
export default {
    name:'Product',
    components:{ Heard,Bottom},
    data(){
        return{
            obj:{},
            bannerHeight:''
        }
    },
    mounted(){
        this.getDetail(this.$route.query.id)
        window.addEventListener(
            "resize",
        () => {
            this.bannerHeight = this.$refs.bannerHeight[0].height; 
            this.imgload();
        },
        false
    );
    },
    methods:{
        imgload(){
            this.$nextTick(()=>{
                this.bannerHeight = this.$refs.bannerHeight[0].height; 
            })
        },
        getDetail(id){
            changpDetail(id).then(res=>{
                this.obj=res.data
                this.imgload();
            })
        },
        menClick(data){
           if(data.id==7){
                this.$router.push('/about')
           }else{
                this.$router.push('/home?md='+data.id)
           }
        }
    }

}
</script>

<style scoped lang="less">
.width{
    width: 1170px;
    margin: 0 auto;
}
.to{
    text-align: left;
    padding: 0px 30px;
    cursor: pointer;
    box-sizing: border-box;
    margin-bottom:10px;
    font-size: 14px;
    color: #333333;
    span:hover{
        color: #124fa0;
    }
}

.info-content{
    padding: 30px;
    box-sizing: border-box;
    text-align: left;
    img{
        display: block;
    }
}

/deep/.el-carousel{
    // height: 656px;
    margin-top: 80px;
    .el-carousel__container{
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: auto;
        }
    }
}
</style>