<template>
    <div class="home" >
          <!-- 头部 -->
          <Heard @menClick="menClick"/>
          <div class="men">
            <div class="pad">
                <div class="pa-item" :class="($route.path==item.path?'avtive':'')" @click="toPath(item)" v-for="item in list" :key="item.tit">{{item.tit}}</div>
            </div>
          </div>
          <div class="box-1">
            <div class="flex" style="marginLeft:384px;height:100%">
              <img src="../../assets/img/bb1.png" class="bb1" alt="">
              <div class="text1">
                 <div>露晰得中国 | 上海有康医疗器械有限公司</div>
                 <br />
                 <div>诞生于2009年的有康，倚借清晰的战略规划、鲜明的学术路径和专业的推广管理，</div>
                <div>业已成为在中国眼视光领域拥有卓越口碑和赞誉的知名品牌。</div>
                <div>这是一家从诞生之日起就思考并明确了使命、价值观和原则的公司，</div>
                <div>这也是有康持续经年一路飞扬的核心动力！</div>

              </div>
            </div>
          </div>
          <div class="box-2">
            <img src="../../assets/img/bb2.jpg" class="bb2" alt="">
          </div>
          <div class="box-3">
                <div class="f28" style="margin:52px auto 28px">我们的使命：有康为什么存在？</div>
                <div class="f16">我们为了提高近视患者的生活品质而存在，</div>
                <div class="f16">我们向广大的近视患者提供优质的产品和专业的服务，点亮他/她们的世界，</div>
                <div class="f16">从而提升生活品质，我们也因此而获得价值实现！</div>
                <img src="../../assets/img/bb3.jpg" class="bb3" alt="">
          </div>
          <div class="box-4">
              <div class="f28 white" style="marginBottom:16px">我们的价值观：是什么让我们汇聚在有康</div>
              <div class="f18 white" style="marginBottom:27px">诚实正直、以人为本、激情创新、共同发展</div>
              <div class="f18 white">人品永远是我们的第一关注点，诚实正直是有康人的品质共性。 我们崇尚“人人生而平等”，充分尊重彼此，诚挚善待自己及所有人。</div>
              <div class="f18 white">我们拒绝平庸，永远激情面对工作与生活，用创新把一切变得更加有趣。 在有康，“我们”的含义包括客户、供应商、同仁、伙伴以及家人。</div>
              <div class="f18 white">共同发展是我们汇聚在一起的动因</div>      
          </div>
          <div class="box-5">
            <img src="../../assets/img/bb4.jpg" class="bb4" alt="">
          </div>
          <div class="box-6">
             <div class="f28 white" style="marginBottom:28px">我们的原则：我们的所有行为必须遵从的标准！</div>
             <div class="f18 white"> <span style="font-weight:bold">质量是我们的生命线</span> | 质量和品质是一切的前提，这是我们的生命线！</div>
             <div class="f18 white"> <span style="font-weight:bold">健康生活，快乐工作</span> | 唯健康，才幸福；唯快乐，才有创造。这是有康文化，也是所有制度之本。</div>
             <div class="f18 white"> <span style="font-weight:bold">简捷</span> | 简单快捷，才能低耗高效。我们以此来评价和审视我们的经营管理。</div>
             <div class="f18 white"> <span style="font-weight:bold">尊重差异与个性</span> | 我们追求价值共鸣，但充分尊重和包容差异与个性，这让我们多姿多彩！</div>
              <img src="../../assets/img/bb5.jpg" class="bb5" alt="">



          </div>
          <div class="box-11">
              <div class="le-con">
                <div class="f28 width70"  style="margin-top:120px;marginBottom:28px">以品牌为己任</div>
                <div class="f16 width70" style="line-height:28px">自创建起，一直秉承公司与产品的核心理念，努力践行；不仅践行“品牌”理念于商品，更是践行于员工、合作伙伴以及行业！</div>
              </div>
              <div class="ri-img">
                <img src="../../assets/img/bb11.png" class="bb11" alt="">
              </div>
          </div>
          <div class="box-7">
             <div class="f28 " style="marginBottom:21px">有康文化——“快乐工作，健康生活”</div>
             <div class="f18 ">  “快乐工作，健康生活”，这是有康的核心文化！</div>
             <div class="f18 "> 这8个字，是所有制度的设计原点，也是解决所有问题的最终仲裁依据！</div>
             <div class="f18 "> 我们坚信：唯有快乐，方有创新；唯有健康，方有未来；唯有这8字，方有惬意职场人生！</div>
              <img src="../../assets/img/bb6.jpg" class="bb6" alt="">
          </div>
          <div class="box-8">
            <img src="../../assets/img/bb7.jpg" class="bb7" alt="">
            <div class="cont">
              <div class="f28" style="marginBottom:23px">有康DNA</div>
              <div class="f18">以人为本</div>
              <div class="f18">我们崇尚源自内心的尊重，于同仁、于同道、于合作商，皆如此；</div>
              <div class="f18">以专业为本</div>
              <div class="f18">不专业，无以立足，这是一个从诞生开始就没有一周停止过专业培训且拥有特色知识平台的公司；</div>
              <div class="f18">以创新为本</div>
              <div class="f18">我们厌倦重复，2.0、3.0版本是我们口头禅，所以我们引领了行业的诸多创新；</div>
              <div class="f18">以文化为本</div>
              <div class="f18">文化是我们的核心纽带，不仅仅是所有制度、规范的源泉，更是我们共鸣思想的提炼；</div>
            </div>
          </div>
          <div class="box-9">
            <div class="left">
                <div class="f28 white" style="marginBottom:27px">有康社会责任与担当</div>
                <div class="f18 white">有康，从创建起就已明确自己的使命：我们是为了提高近视患者的生活品质而存在!</div>
                <div class="f18 white">使命的驱使，让我们从患者、行业、社稷等多个维度和多个层面来综合思考，并为此而奋斗</div>
                <div class="f18 white">所以，这里会有“中国眼视光英才培育计划——明日之星”</div>
                <div class="f18 white">“雅安公益行动”、“家关怀”等多层级、多对象的社会责任与担当!</div>
            </div>
            <div class="right">
              <img src="../../assets/img/bb8.jpg" class="bb8" alt="">
            </div>
          </div>
           <div class="box-10">
              <div class="f28" style="margin-bottom:28px">有康愿景</div>
              <div class="f16">有康，矢志于助推和引领中国眼视光的发展，成为一家受人尊敬的公司！</div>
              <div class="bb9">
                <img src="../../assets/img/bb9.jpg" alt="" />
              </div>
           </div>
          <bottom />
    </div>
  </template>
  
  <script>
  import Heard from '../../components/heard.vue'
  import Bottom from '@/components/bottom.vue'
  export default {
      name:'China',
      components:{ Heard,Bottom},
      data(){
          return{
            list:[
            {tit:'露晰得中国',path:'/china'},
                {tit:'露晰得韩国',path:'https://www.lucid.co.kr/default/'},
                {tit:'加入露晰得',path:'/about'},
                {tit:'联系我们',path:'/contact'}
            ]
          }
      },
      mounted(){
        console.log(this.$route.path)
      },
      methods:{
        toPath(item){
          if(item.tit!='露晰得韩国'){
                if(item.path==this.$route.path) return
                this.$router.push(item.path)
            }else{
                window.location.href=item.path   
            }
        },
        menClick(data){
           if(data.id==7){
                this.$router.push('/about')
           }else{
                this.$router.push('/home?md='+data.id)
           }
        }
      }
  
  }
  </script>
  
  <style scoped lang="less">
  .width70{
    width: 70%;
    text-align: left;
    margin-left: 30%;
  }
  .box-11{
    display: flex;
    width: 100%;
    height: 370px;
    .le-con{
      width: 45%;
      
    }
    .ri-img{
      width: 55%;
      .bb11{
        width: 750px;
        height: 370px;
      }
    }
  }
  .box-10{
    width: 100%;
    padding-top: 30px;
    box-sizing: border-box;
    .bb9{
      text-align: left;
      img{
        width: 1349px;
      }
    }
  }
  .box-9{
    width: 100%;
    height: 376px;
    background: #3399cc;
    display: flex;
    .left{
      text-align: right;
      width: 50%;
      padding-top: 96px;
      box-sizing: border-box;
    }
    .right{
      text-align: left;
      width: 50%;
      .bb8{
        width:751px;
        height: 376px;
      }
    }
  }
  .box-8{
    display: flex;
    height: 415px;
    .bb7{
      width: 750px;
      height: 415px;
    }
    .cont{
      width: calc(100% - 750px);
      height: 415px;
      padding-top: 72px;
      box-sizing: border-box;
    }
  }
    .box-7{
    width: 100%;
    padding-top: 70px;
    margin-top: -4px;
    box-sizing: border-box;
    background: #E7F1E8;
    .bb6{
      width: 1349px;
      display: block;
      margin: 0 auto !important;
    }
  }
  .box-6{
    width: 100%;
    padding-top: 33px;
    // margin-top: -4px;
    box-sizing: border-box;
    background: #336699;
    .bb5{
      display: block;
      margin: 0 auto;
      margin-top: 39px;
      width: 1349px;
    }
  }
  .white{
    color: #FFFFFF !important;
  }
  .f28{
    font-size: 28px;
    color: #333333;
  }
  .f16{
    font-size: 16px;
    color: #333333;
    line-height: 24px;
  }
  .f18{
    font-size: 18px;
    color: #333333;
    line-height: 28px;
  }
  .box-4{
    height: 250px;
    // margin-top: -4px;
    padding-top: 23px;
    box-sizing: border-box;
    background: #009999;
  }
  .box-3{
    text-align: center;
    width: 100%;
    .bb3{
      display: block;
      width:1349px;
      margin: 0 auto;
    }
  }
  .box-1{
    width: 100%;
    height: 300px;
    background: #006699;
    text-align: left;
    .bb1{
      width: 400px;
      margin-right:50px;
    }
    .text1{
      // font-family: Geneva, Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    TEXT-DECORATION: none;
    }
  }
  .box-2{
    text-align: right;
    .bb2{

      width: 1349px;
      height: 400px;
    }
  }
  .box-5{
    display: flex;
    justify-content: flex-start;
    .bb4{
      display: block;
      width: 1349px;
    }
  }
  .flex{
    display: flex;
    align-items: center;
  }
  .avtive{
    color: #c2c2c2 !important;
  }
  .avtive:hover{
        color: #124fa0 !important;
  }
  .width{
      width: 1170px;
      margin: 0 auto;
  }
  .pad{
    width: 600px;
    margin: 0 auto;
    height: 45px;
    display: flex;
    align-items: center;
    .pa-item{
        width: 25%;
        text-align: center;
        font-size: 12px;
        cursor: pointer;
        color: #FFFFFF;
    }
    .pa-item:hover{
        color: #4E9AF7;
    }
  }
  .men{
    margin-top: 80px;
    background-color: #666666;
    height: 45px;
  }
  </style>