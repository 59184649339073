<template>
  <div class="home">
    <!-- 头部 -->
    <Heard @menClick="menClick"  />
    <!-- 轮播 -->
    <el-carousel arrow="never"  :height="bannerHeight + 'px'"    :interval="3000" :autoplay="true">
      <el-carousel-item v-for="item in lbList" :key="item.id">
        <img :src="item.bannerImg" style="width: 100%; height: auto"   @load="imgload" ref="bannerHeight"   alt="">
      </el-carousel-item>
    </el-carousel>
    <!-- 露晰得产品 -->
    <!-- <div id="2" class="width">
        <div class="mainTit">
            <p class="mainText">露晰得产品</p>
            <p class="descText">LUCID&nbsp;&nbsp;PRODUCTS</p>
        </div>
        <el-row  class="cp-list">
            <el-col :span="6" v-for="item in cpList" :key="item.id" >
              <div class="cp-item" @click="toDetail('/product?id='+item.id)"> 
                    <div class="img-cp">
                        <div class="img-mc"></div>
                        <img  :src="item.thumbnail" alt=""/>
                    </div>
                    <div class="img-tit">{{item.title}}</div>
              </div>
            </el-col>
        </el-row>
    </div> -->
    <!-- 用户服务 -->
    <div id="3">
        <div class="mainTit">
            <p class="mainText">用户服务</p>
            <p class="descText">USER&nbsp;&nbsp;SERVICE</p>
        </div>
        <div class="fw-box">
            <div class="fw-list">
            <div class="fw-item" v-for="item in fwList" :key="item.name" @click="codeClick(item)" @mouseenter="(item.show=false)" @mouseleave="(item.show=true)">
             <div class="fw-mc"></div>
             <img :src="require('../../assets/img/'+item.img+'.png')" class="imgs" alt="">
             <div>
                <p class="fw-name">{{item.name}}</p>
                <p class="fw-value">{{item.value}}</p>
             </div>
             <div>
                <img class="left_" v-if="item.show" src="../../assets/img/left_.png" alt=""/>
                <img class="left_" v-else src="../../assets/img/left_a.png" alt=""/>
            </div>
            </div>
        </div>
        </div>
    </div>
    <!-- 露粉社区 -->
    <div id="4" class="width">
        <div class="mainTit">
            <p class="mainText">露粉社区</p>
            <p class="descText">LUCID&nbsp;&nbsp;COMMUNITY</p>
        </div>
        <div class="sq-box"  @click="showClick(0)">
            <img  :src="sqObj.tilteImg" alt="">
            <div class="sq-right">
                <div class="sq-tit" v-html="sqObj.title"></div>
                 <div class="sq-list">
                        <div class="sq-item" v-for="item in sqList" :key="item.tit">
                            <img :src="require('../../assets/img/'+item.img+'.png')" alt="">
                            <div class="sq-com">{{item.tit}}</div>
                            <div class="sq-mc" ></div>
                        </div>
                 </div>
                 <div class="more" @click="show=true">点击查看</div>
            </div>
        </div>
        <div class="h-60"></div>
    </div>
    <!-- 露晰得眼视光云课堂 -->
    <div id="5" class="bgc">
        <div class="width">
            <div class="mainTit">
            <p class="mainText">露晰得眼视光云课堂</p>
            <p class="descText">LUCID&nbsp;&nbsp;OPTOMETRY ACADEMY</p>
        </div>
        <div class="sq-box" style="border:0" @click="showClick(1)">
            <div class="sq-right">
                <div class="sq-tit" v-html="ktObj.title"></div>
                 <div class="sq-list" style="padding:0;marginLeft:0;marginRight:0">
                        <div class="sq-item" style="width:16.7%"  v-for="item in ktList" :key="item.tit">
                            <img  class="imgss" :src="require('../../assets/img/'+item.img+'.png')" alt="">
                            <div class="sq-com">{{item.tit}}</div>
                            <div class="sq-mc" ></div>
                        </div>
                 </div>
                 <div class="more" @click="show=true">点击查看</div>
            </div>
            <img  :src="ktObj.tilteImg" alt="">
        </div>
        <div class="h-60"></div>
        </div>
    </div>
    <!-- 最新动态 -->
    <div id="6" style="background:rgb(248,248,248)">
        <div class="width">
            <div class="mainTit">
            <p class="mainText">最新动态</p>
            <p class="descText">NEWS&nbsp;&nbsp;FEED</p>
        </div>
        <div class="new-list">
            <div class="new-item" v-for="item in newsList" :key="item.img" @click="toHearf(item.jumpLink)" >
                <img :src="item.titleImg" class="img" alt="">
                <div class="new-bo">
                    <div class="newsTexts">{{item.title}}</div>
                <div class="new-fl">
                    <span>{{item.createTime?item.createTime.substring ( 0, 10 ):'--'}}</span>
                    <span>了解更多</span>
                </div>
                </div>
            </div>
        </div>
        <div class="pagination" v-if="(total>0)">
            <el-pagination
                background
                :current-page.sync="currentPage"
                :page-size="6"
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total">
            </el-pagination>
        </div>
        </div>
    </div>
    <!-- 关于露晰得 -->
    <div id="1" style="background:rgb(245,245,245)">
        <div class="width">
            <div class="mainTit">
            <p class="mainText">关于露晰得</p>
            <p class="descText">ABOUT&nbsp;&nbsp;LUCID</p>
        </div>
        <div class="about">
            <img src="../../assets/img/about.jpg" @click="$router.push('/china')" class="about-top" alt="">
            <div class="about-flex">
            <div class="flex-item" v-for="item in gyList" :key="item.id" @click="pathClick(item)">
                <div class="flex-img">
                    <img :src="item.titleImg" alt="" />
                    <div class="mc-img"></div>
                </div>
                <div class="flex-tit">{{item.title}}</div>
            </div>
        </div>
        <div style="height:60px"></div>
        </div>
        </div>
    </div>
    <!-- 露晰得官方账号 -->
    <div class="width">
        <div class="mainTit">
            <p class="mainText">露晰得官方账号</p>
            <p class="descText">LUCID&nbsp;&nbsp;OFFICIAL&nbsp;&nbsp;ACCOUNT</p>
        </div>
        <div class="a-flex">
            <div class="a-item" @click="toHearf('https://weibo.com/lucidchn')">
                <img src="../../assets/img/a1.png" alt="">
                <div class="xian"></div>
                <div class="a-tit1">露晰得官方微博</div>
                <div class="a-tit2">实时活动更新，信息尽在掌握</div>
            </div>
            <div class="huix"></div>
            <div class="a-item"  @click="userClick">
                <img src="../../assets/img/a2.jpg" alt="">
                <div class="xian"></div>
                <div class="a-tit1">露晰得官方微信</div>
                <div class="a-tit2">掌握露晰得最新动态</div>
            </div>
            <div class="huix"></div>
            <div class="a-item"  @click="toHearf('https://www.zhihu.com/people/lu-xi-de-zhong-guo')">
                <img src="../../assets/img/a3.jpg" alt="">
                <div class="xian"></div>
                <div class="a-tit1">露晰得官方知乎</div>
                <div class="a-tit2">以专业的知识，揭开您对硬核的提问</div>
            </div>
            <div class="huix"></div>
            <div class="a-item"  @click="toHearf('https://www.xiaohongshu.com/user/profile/5e954b820000000001008aa9?xhsshare=CopyLink&appuid=5e954b820000000001008aa9&apptime=1603173768')">
                <img src="../../assets/img/a4.jpg" alt="">
                <div class="xian"></div>
                <div class="a-tit1">露晰得官方小红书</div>
                <div class="a-tit2">标记我的日常戴镜生活</div>
            </div>
        </div>
    </div>
    <bottom />
    <!-- 小程序码 -->
    <div class="loading" v-if="show" @click="show=false">
        <div class="prop" @click.stop="show=true">
            <img v-if="codeShow" :src="require('../../assets/img/'+modelImg+'.png')" alt="">
            <img v-else :src="modelImg" alt="">
        </div>
    </div>

  </div>
</template>

<script>
import Heard from '../../components/heard.vue'
import Bottom from '@/components/bottom.vue'
import {getBanner,getChangp,getCommunity,getNews,getLxd} from '../../api/index'
export default {
    name:'Home',
    components:{ Heard,Bottom},
    data(){
        return{
            show:false,
            bannerHeight:'', 
            modelImg:'../../assets/img/code.png',
            // 轮播
            lbList:[],
            // 社区
            sqObj:{},
            // 课堂
            ktObj:{},
            codeShow:false,
            // 网点
            wdObj:{},
            // 用户服务
            fwList:[
                {name:'镜片验真',value:'辨别真伪 放心使用',img:'fu1',show:true,code:'code1'},
                {name:'附近网点',value:'查询您附近的配验网点',img:'fu2',show:true,code:'code2'},
                {name:'视频指南',value:'在线观看 贴心呵护',img:'fu3',show:true,code:'code3'},
                {name:'常见问题',value:'快速解决 一键搜索',img:'fu4',show:true,code:'code4'},
                {name:'在线咨询',value:'可与Dr.露一对一沟通',img:'fu5',show:true,code:'code5'},
                {name:'爱眼热线',value:'400 161 0606',img:'fu6',show:true}
            ],
            // 产品
            cpList:[],
            // 社区
            sqList:[
                {tit:'露粉社区',img:'sq1',show:false},{tit:'最新活动',img:'sq2',show:false},{tit:'在线咨询',img:'sq3',show:false},{tit:'附近网点',img:'sq4',show:false},{tit:'健康档案',img:'sq5',show:false}
            ],
            // 课堂
            ktList:[
                {tit:'公开课',img:'kt1'},
                {tit:'微讲堂',img:'kt2'},
                {tit:'论文集',img:'kt3'},
                {tit:'专业圈',img:'kt4'},
                {tit:'晰望星',img:'kt5'},
                {tit:'福利社',img:'kt6'},
            ],
            // 新闻
            newsList:[
                {
                    img:'https://www.lucidkorea.com/Upload/images/2020/11/30/th-1645208391.jpg',
                    title:'露晰得中国2014第一期技术骨干高阶研讨班侧记12',
                    time:'2014-04-24'
                },
                {
                    img:'https://www.lucidkorea.com/Upload/images/2020/11/30/th-1645208391.jpg',
                    title:'露晰得中国2014第一期技术骨干高阶研讨班侧记12',
                    time:'2014-04-24'
                },
                {
                    img:'https://www.lucidkorea.com/Upload/images/2020/11/30/th-1645208391.jpg',
                    title:'露晰得中国2014第一期技术骨干高阶研讨班侧记12',
                    time:'2014-04-24'
                },
                {
                    img:'https://www.lucidkorea.com/Upload/images/2020/11/30/th-1645208391.jpg',
                    title:'露晰得中国2014第一期技术骨干高阶研讨班侧记12',
                    time:'2014-04-24'
                },
                {
                    img:'https://www.lucidkorea.com/Upload/images/2020/11/30/th-1645208391.jpg',
                    title:'露晰得中国2014第一期技术骨干高阶研讨班侧记12',
                    time:'2014-04-24'
                }
            ],
            gyList:[
                {img:'https://www.lucidkorea.com/Upload/images/2020/12/30/1557414745.jpg',title:'露晰得中国'},
                {img:'https://www.lucidkorea.com/Upload/images/2020/12/30/1557414745.jpg',title:'露晰得韩国'},
                {img:'https://www.lucidkorea.com/Upload/images/2020/12/30/1557414745.jpg',title:'加入露晰得'},
                {img:'https://www.lucidkorea.com/Upload/images/2020/12/30/1557414745.jpg',title:'联系我们'}
            ],
            //分页
            currentPage:1, 
            total:0
        }
    },
    mounted(){
        this.getBannerList()
        this.getChangpList()
        this.getCommunityList()
        this.getNewsList()
        this.getLxdList()
        if(this.$route.query.md){
            var anchor = document.getElementById(this.$route.query.md);
                anchor.scrollIntoView()
        }
        this.imgload();   
        window.addEventListener(
            "resize",
        () => {
            this.bannerHeight = this.$refs.bannerHeight[0].height; 
            this.imgload();
        },
        false
    );
    },
    methods:{
        imgload(){
            this.$nextTick(()=>{
                this.bannerHeight = this.$refs.bannerHeight[0].height; 
            })
        },
        // 获取关于路
        getLxdList(){
            getLxd().then(res=>{
                this.gyList=res.rows
            })
        },
        // 获取轮播列表
        getBannerList(){
            getBanner().then(res=>{
                this.lbList=res.rows
            })
        },
        // 获取产品
        getChangpList(){
            getChangp().then(res=>{
                this.cpList=res.rows
            })
        },
        // 获取二维码等
        getCommunityList(){
            getCommunity().then(res=>{
                res.rows.forEach(i=> {
                    if(i.type==0){
                        this.sqObj=i
                    }
                    if(i.type==1){
                        this.ktObj=i
                    }
                    if(i.type==2){
                        this.wdObj=i
                    }
                });
            })
        },
        // 获取咨询
        getNewsList(){
            let params={
                pageNum:this.currentPage,
                pageSize:6
            }
            getNews(params).then(res=>{
                this.newsList=res.rows
                this.total=res.total
            })
        },  
        // 分页
        handleCurrentChange(data){
            this.currentPage=data
            this.getNewsList()
        },
        codeClick(item){
            if(item.code){
                this.codeShow=true
                this.modelImg=item.code
                this.show=true
            }
        },
        userClick(){
            this.codeShow=true
                this.modelImg='code6'
                this.show=true
        },
        showClick(type){
            this.codeShow=false
            if(type==0) this.modelImg=this.sqObj.qrCode
            if(type==1) this.modelImg=this.ktObj.qrCode
            if(type==3) this.modelImg=this.wdObj.qrCode
            this.show=true
        },
        toDetail(url){
            this.$router.push(url)
        },  
        toHearf(url){	
            window.location.href=url   
        },
        pathClick(item){
            if(item.title=='露晰得韩国'){
                this.toHearf(item.jumpLink)
            }else{
                this.$router.push(item.jumpLink)
        }
        },    
        // 菜单点击
        menClick(data){
           if(data.id==7){
                this.$router.push('/about')
           }else{
                var anchor = document.getElementById(data.id);
                anchor.scrollIntoView()
           }
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.el-input__inner {
    &::placeholder {
      color: #555555;
    }
  }
.huix{
    height:2px;
    background-color: #f5f5f5;
    width: 30px;
    margin-top: 46px;
}
// 露晰得官方账号
.a-flex{
    width: 100%;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    .a-item{
        width: 262px;
        text-align: center;
        img{
            width: 100%;
            height: 77px;
        }
        .xian{
            width: 80%;
            background: #e5e5e5;
            height: 2px;
            margin: 0 auto;
        }
        .a-tit1{
            font-size: 16px;
            color: #000000;
            padding: 20px 0 10px 0px;
            box-sizing: border-box;
        }
        .a-tit2{
            font-size: 12px;
            height: 40px;
            color: #000000;
        }
    }
    .a-item:hover{
        .a-tit1{
            color: #124fa0;
        }
        .a-tit2{
            color: #124fa0;
        }
    }
}
// 关于露晰得
.about{
    width: 100%;
    cursor: pointer;
    .about-top{
        width: 100%;
        height: 300px;
        margin-bottom: 10px;
    }
    .about-flex{
        display: flex;
        justify-content: space-between;
        .flex-item{
            margin-right: 10px;
            .flex-img{
                width: 284px;
                height: 190px;
                overflow: hidden;
                position: relative;
                img{
                    width: 284px;
                    height: 190px;
                    transition: all 0.3s ease-out;
            }
            .mc-img{
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                display: none;
                z-index: 1;
                background:rgba(0, 0, 0, 0.4);
            }
            }
            .flex-tit{
                color: #3d3a39;
                font-size: 16px;
                margin: 18px auto 25px;
            }
        }
        .flex-item:hover{
            background: #F8F8F8;
            .flex-img{
                img{
                   transform: scale(1.3);
                }
            }
            .mc-img{
                display: block;
            }
        }
        .flex-item:nth-child(4){
            margin-right: 0;
        }
    }
}
// 最新咨询
.new-list{
    display: flex;
    flex-wrap: wrap;
    .new-item{
        width: 380px;
        margin-right: 15px;
        margin-bottom: 20px;
        overflow: hidden;
        cursor: pointer;
        background-color: #fff;
        .img{
            display: block;
            width: 100%;
            height: 180px;
        }
        .new-bo{
            border-bottom: 1px solid #FFFFFF;
        }
        .new-bo:hover{
            border-bottom: 1px solid #333333;
        }
        .newsTexts{
            padding: 15px 15px 0px 15px;
            box-sizing: border-box;
            text-align: left;
            line-height: 24px;
            height: 63px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            font-size: 16px;
            color: #000000;
        }
        .new-fl{
            padding: 10px 15px 10px 15px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            color: #000000;
        }
        
    }
    .new-item:hover{
        .newsTexts{
            color: #999999;
        }
        .new-fl{
            color: #124fa0;
        }
    }
    .new-item:nth-child(3n){
        margin-right: 0;
    }
}
.pagination{
    margin: 20px auto 0;
    padding-bottom: 60px;
    box-sizing: border-box;
    .active{
        color: #124fa0;
    }
}
// 露水社区
.sq-box{
    width: 100%;
    height: 339px;
    box-sizing: border-box;
    position: relative;
    border: 1px solid lightgray;
    display: flex;
    img{
        width: 50%;
        height: 100%;
    }
    .sq-right{
        width: 50%;
        height: 100%;
        display: flex;
        padding: 20px 0;
        box-sizing: border-box;
        justify-content: space-around;
        flex-direction:column;
        background-color: #fff;
        .sq-tit{
            font-size: 20px;
            color: #3d3a39;
            line-height: 36px;
        }
        .sq-list{
            display: flex;
            justify-content: center;
            margin: 30px auto;
            padding: 0 30px;
            box-sizing: border-box;
            .sq-item{
                position: relative;
                padding: 10px 15px 0 15px;
                overflow: hidden;
                cursor: pointer;
                box-sizing: border-box;
                img{
                    width: 40px;
                    height: 40px;
                    transition: all 0.3s ease-out;
                    margin-bottom: 10px;
                }
                .imgss{
                    transition: all 0.3s ease-out;
                    width: 54px;
                    height: 54px;
                }
                .sq-com{
                    font-size: 16px;
                    color: gray;
                    margin: 5px auto 10px;
                }
                .sq-mc{
                    position: absolute;
                    left: 0;
                    right: 0;
                    top:0;
                    bottom: 0;
                    display: none;
                    z-index: 1;
                    background: rgba(0, 0, 0, .3);
                }
            }
            .sq-item:hover{
                .sq-mc{
                    display: block;
                }
                img{
                    transform: scale(1.3);
                }
            }
        }
    }
}
// 用户服务
.fw-box{
    position: relative;
    padding-bottom:30%;
    height:0;
}
.fw-list{
    position: absolute;width:100%;height:100%;
    display: flex;
    .left_{
        width: 35px !important;
        transition: all 0.3s ease-out;
        height: 35px !important;
    }
    .fw-item{
        width: 16.666666666666664%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
        padding: 15px 0 20px 0;
        // height: 570px;
        position: relative;
        box-sizing: border-box;
        border-right: 1px solid #FFFFFF;
        border-bottom: 1px solid #FFFFFF;
        .imgs{
            width: 79px;
            transition: all 0.3s ease-out;
            height: 79px;
        }
        .fw-name{
            font-size: 16px;
            color: #FFFFFF;
            margin-bottom: 10px;
            font-weight:700;
        }
        .fw-value{
            font-size: 12px;
            color:#FFFFFF;
            font-weight:normal      
        }
        .fw-mc{
            position: absolute;
            left: 0;
            right: 0;
            overflow: hidden;
            top: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.4);
            display: none;
            z-index: 1;
        }
    }
    .fw-item:hover{
        .fw-mc{
            display: inline-block;
        }
        .imgs{
            transform: scale(1.3)
        }
    }
    .fw-item:nth-child(1){
        background: linear-gradient(to right,rgb(13,92,161),rgb(11,104,161));
    }
    .fw-item:nth-child(2){
        background:linear-gradient(to right,rgb(11,104,161),rgb(13,116,161));
    }
    .fw-item:nth-child(3){
        background:linear-gradient(to right,rgb(13,116,161),rgb(11,128,161));
    }
    .fw-item:nth-child(4){
        background: linear-gradient(to right,rgb(11,128,161),rgb(12,139,156));
    }
    .fw-item:nth-child(5){
        background: linear-gradient(to right,rgb(12,139,156),rgb(12,150,152));
    }
    .fw-item:nth-child(6){
        background: linear-gradient(to right,rgb(12,150,152),rgb(12,160,148));
        border-right: 0;
    }


}
// 产品
.cp-list{
    width: 100%;
    .cp-item{
        padding: 0 15px;
        box-sizing: border-box;
        margin-bottom: 90px;
        cursor: pointer;
        position: relative;
        .img-mc{
            position: absolute;
            left: 0;
            right: 0;
            overflow: hidden;
            top: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.4);
            display: none;
            z-index: 1;
        }
        .img-cp{
            width: 262px;
            height: 262px;
            overflow: hidden;
            position: relative;
            img{
                width: 100%;
                transition: all 0.3s ease-out;
                height: 100%;
            }
        }
        .img-tit{ 
            font-size: 16px;
            margin-top: 20px;
            color: #3d3a39;
            margin-bottom: 10px;
        }
        .img-tit:hover{
            color: #124fa0;
        }
    }
    .cp-item:hover{
        .img-mc{
            display: block;
        }
        .img-cp{
           img{
            transform: scale(1.2)
           }
        }
    }
}
.h-60{
    height: 60px;
}
.bgc{
    background:rgb(245,245,245)
}
.width{
    width: 1170px;
    margin: 0 auto;
}
.more{
    font-size: 18px;
    color: gray;
    cursor: pointer;
}
.more:hover{
    color: #124fa0;
}
.mainTit{
    padding: 60px 0 30px;
    text-align: center;
    box-sizing: border-box;
    .mainText{
        font-size: 30px;
        color: #3d3a39;
        // transform: scale(0.7);
        font-weight: bold;
        margin-bottom: 5px;
    }
    .descText{
        font-size: 23px;
        transform: scale(0.7);
        color: #747474;
    }
}
// 轮播
/deep/.el-carousel{
    // height: 810px;
    // height: 100%;
    margin-top: 80px;
    .el-carousel__container{
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .el-carousel__button{
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }
}
// 蒙层
.loading{
 position: fixed;
 background: rgba(0,0,0,0.5);
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 z-index: 998;
 display: flex;
    justify-content: center;
    align-items: center;
 .prop{
    width: 330px;
    height: 330px;
    border-radius: 30px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 301px;
        height: 301px;
    }
 }
}
</style>