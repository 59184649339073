<template>
  <div class="search">
    <div class="sear-top" @click.stop="searchShow = true">
      <div class="width">
        <img src="../../assets/img/search.png" class="se-img" alt="" />
        <el-input
          class="input"
          v-model="input"
          @keyup.enter.native="change"
          placeholder="请输入内容"
        />
      </div>
    </div>
    <div class="conents">
      <div class="mainTit">
        <p class="mainText">搜索结果</p>
        <p class="descText">SEARCH RESULT</p>
      </div>
      <div class="new-list" v-if="newsList.length > 0">
        <div
          class="new-item"
          v-for="item in newsList"
          :key="item.img"
          @click="toHearf(item.jumpLink)"
        >
          <img :src="item.titleImg" class="img" alt="" />
          <div class="newsTexts">{{ item.title }}</div>
          <div class="new-fl">
            <span>{{ item.createTime.substring ( 0, 10 ) }}</span>
            <span>了解更多</span>
          </div>
        </div>
      </div>
      <div class="more" v-else>请换个关键词叭~</div>
      <div class="pagination" v-if="total > 0">
        <el-pagination
          background
          :current-page.sync="params.pageNum"
          :page-size="8"
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getNews } from "../../api/index";
export default {
  name: "Search",
  data() {
    return {
      params: {
        title: "",
        pageNum: 1,
        pageSize: 8,
      },
      input:'',
      newsList: [],
      total: 0,
    };
  },
  mounted() {
    this.params.title = this.$route.query.value;
    this.getNewsList();
  },
  methods: {
    change(value) {
      this.params.title = value;
      this.params.pageNum = 1;
      this.getNewsList();
    },
    handleCurrentChange(data) {
      this.params.pageNum = data;
      this.getNewsList();
    },
    toHearf(url){	
        window.location.href=url   
    },    
    // 获取咨询
    getNewsList() {
      getNews(this.params).then((res) => {
        this.newsList = res.rows;
        this.total = res.total;

      });
    },
  }
};
</script>

<style lang="less" scoped>
/deep/.el-input__inner {
    &::placeholder {
      color: #555555;
    }
  }
.more {
  text-align: center;
  padding: 30px;
  box-sizing: border-box;
  font-size: 20px;
  color: #333333;
}
.pagination {
  margin: 20px auto 0;
  padding-bottom: 60px;
  box-sizing: border-box;
  .active {
    color: #124fa0;
  }
}
.conents {
  width: 1170px;
  margin: 0 auto;
  .mainTit {
    padding: 60px 0 30px;
    text-align: center;
    box-sizing: border-box;
    .mainText{
        font-size: 30px;
        color: #3d3a39;
        // transform: scale(0.7);
        font-weight: bold;
        margin-bottom: 5px;
    }
    .descText{
        font-size: 23px;
        transform: scale(0.7);
        color: #747474;
    }
  }
}
.new-list {
  display: flex;
  flex-wrap: wrap;
  .new-item {
    width: 282px;
    margin-right: 12px;
    margin-bottom: 20px;
    overflow: hidden;
    cursor: pointer;
    background-color: #fff;
    // border-bottom: 1px solid #FFFFFF;
    .img {
      width: 100%;
      display: block;
      height: 136px;
    }
    .newsTexts {
      padding: 15px 15px 0px 15px;
      box-sizing: border-box;
      text-align: left;
      line-height: 24px;
      height: 63px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      font-size: 16px;
      color: #000000;
    }
    .new-fl {
      padding: 10px 15px 10px 15px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: #000000;
    }
  }
  .new-item:hover {
    // border-bottom: 1px solid #333333;
    .new-fl{
      color: #124fa0;
    }
    .newsTexts {
      color: #999999;
    }
  }
  .new-item:nth-child(4n) {
    margin-right: 0;
  }
}
/deep/.el-input {
  width: 80%;
}
/deep/.el-input__inner {
  width: 100%;
  height: 40px;
  border: 1px solid lightgray;
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 30px;
  outline: none;
}
.width {
  width: 1170px !important;
  height: 80px;
  padding: 0 120px;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search {
  padding-top: 80px;
  background-color: #f8f8f8;
  min-height: calc(100vh - 80px);
  position: relative;
  .sear-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgb(248, 248, 248);
    z-index: 9999;
    width: 100%;
    height: 80px;
    background-color: #fff;
    .se-img {
      width: 45px;
      height: 45px;
      margin-right: 15px;
    }
  }
}
</style>