<template>
  <div class="heard">
    <div class="container">
      <div class="left">
        <a href="" @click="this.$router.push('/home')">
          <img src="../assets/img/logo.jpg" alt="" />
        </a>
        <div class="center">
          <a
            class="menu"
            href="javascript:;"
            v-for="item in menList"
            :key="item.id"
            @click="Click(item)"
            >{{ item.name }}</a
          >
        </div>
      </div>
      <div class="right">
        <img src="../assets/img/search.png" @click="searchShow = true" alt="" />
        <img
          src="../assets/img/address.png"
          @mouseenter="imgShow = true"
          @mouseleave="imgShow = false"
          alt=""
        />
        <div class="posi-box" v-if="imgShow">
          <div class="box-tit">{{ ObJ.title }}</div>
          <img class="imgs" :src="ObJ.qrCode" alt="" />
        </div>
        <a class="button" href="https://www.lucid.co.kr/default/">韩国官网</a>
      </div>
    </div>
    <div class="search" v-if="searchShow" @click="searchShow = false">
      <div class="sear-top" @click.stop="searchShow = true">
        <div class="width">
          <img src="../assets/img/search.png" class="se-img" alt="" />
          <el-input
            class="input"
            v-model="input"
            @change="change"
            placeholder="请输入内容"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCommunity } from "../api/index";
export default {
  name: "Heard",
  data() {
    return {
      imgShow: false,
      searchShow: false,
      ObJ: {},
      input: "",
      menList: [
        { id: 1, name: "关于露晰得" },
        //{ id: 2, name: "露晰得产品" },
        { id: 3, name: "用户服务" },
        { id: 4, name: "露粉社区" },
        { id: 5, name: "露晰得眼视光云课堂" },
        { id: 6, name: "最新动态" },
        { id: 7, name: "加入我们" },
      ],
    };
  },
  mounted() {
    this.getCommunityList();
  },
  methods: {
    Click(item) {
      this.$emit("menClick", item);
    },
    // 获取二维码等
    getCommunityList() {
      getCommunity().then((res) => {
        res.rows.forEach((i) => {
          if (i.type == 2) {
            this.ObJ = i;
          }
        });
      });
    },
    // 监听回车
    change(value) {
      this.$router.push("/search?value=" + value);
    },
  },
};
</script>

<style lang="less" scoped>
.heard {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 80px;
  z-index: 9999;
  background-color: #fff;
  .posi-box {
    position: absolute;
    top: 75px;
    width: 150px;
    height: 150px;
    border-radius: 10px;
    padding: 12px;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    color: #000000;
    background: #ffffff;
    .imgs {
      margin-top: 10px;
      margin-left: 0 !important;
      width: 100px !important;
      height: 100px !important;
    }
  }
  .container {
    width: 1170px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      width: calc(100% - 175px);
      display: flex;
      img {
        width: 210px;
        margin-right: 15px;
      }
      .center {
        width: calc(100% - 210px);
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0px 60px;
        box-sizing: border-box;
        .menu {
          min-width: 60px;
          font-size: 14px;
          color: #000000;
          margin-left: 0px;
          cursor: pointer;
          margin-left: 5px;
        }
        .menu:hover {
          color: #124fa0;
          // font-size: 16px;
          transform: scale(1.15);
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
        margin-left: 12px;
      }
      .button {
        display: inline-block;
        width: 78px;
        height: 40px;
        border-radius: 22px;
        text-align: center;
        line-height: 40px;
        margin-left: 12px;
        border: 1px solid #000000;
        color: #000000;
        font-size: 14px;
      }
      .button:hover {
        color: #124fa0;
        border: 1px solid #124fa0;
      }
    }
  }
}
.width {
  width: 1170px !important;
  height: 80px;
  padding: 0 120px;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/.el-input {
  width: 80%;
}
/deep/.el-input__inner {
  width: 100%;
  height: 40px;
  border: 1px solid lightgray;
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 30px;
  outline: none;
}
.search {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  .sear-top {
    width: 100%;
    height: 80px;
    background-color: #fff;
    .se-img {
      width: 45px;
      height: 45px;
      margin-right: 15px;
    }
  }
}
</style>