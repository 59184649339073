<template>
    <div class="home" >
          <!-- 头部 -->
          <Heard @menClick="menClick"/>
          <div class="men">
            <div class="pad">
                <div class="pa-item" :class="($route.path==item.path?'avtive':'')" @click="toPath(item)" v-for="item in list" :key="item.tit">{{item.tit}}</div>
            </div>
          </div>
          <div style="textAlign:left">
            <img  class="about-img" src="https://lucidkorea.com/Templates/CompanyDefault/lucid/contact.jpg" alt=""/>
          </div>
          <div class="width p-120">
            <div class="flex">
                <div class="flex-left">
                    <div class="t-24 mb10">联系我们</div>
                    <div class="t-18 mb10">露晰得中国</div>
                    <div class="t-18 mb10">上海有康医疗器械有限公司</div>
                    <div style="height:35px"></div>
                    <div class="t-14 mb10">客服热线：400 161 0606</div>
                    <div class="t-14 mb10">企业邮箱：lucid@ucaresh.com</div>
                    <div class="t-14 mb10">企业招聘：lucid@ucaresh.com</div>
                    <div class="t-14 mb10">渠道合作：yetao@ucaresh.com</div>
                    <div class="t-14 mb10">总机：+86-21-58305301 /02 /90 /93</div>
                    <div class="t-14 mb10">传真：总机转8013</div>
                    <div class="t-14 mb10">地址：上海市浦东新区樱花路100号富荟广场A栋502室</div>
                </div>
                <img src="../../assets/img/addr.png" class="add" alt=""/>
            </div>
            <div style="height:80px"></div>
            <div class="t-24 mb10">露晰得韩国</div>
            <div style="height:20px"></div>
            <div class="t-14 mb10">生产厂：韩国Lucid有限公司</div>
            <div class="t-14 mb10">生产地址：韩国庆尚北道奉化郡奉化邑1农工园区路41号</div>
            <div class="t-14 mb10">韩国网址：www.lucid.co.kr</div>
            <div style="height:20px"></div>
          </div>
          <bottom />
    </div>
  </template>
  
  <script>
  import Heard from '../../components/heard.vue'
  import Bottom from '@/components/bottom.vue'
  export default {
      name:'Product',
      components:{ Heard,Bottom},
      data(){
          return{
            list:[
            {tit:'露晰得中国',path:'/china'},
            {tit:'露晰得韩国',path:'https://www.lucid.co.kr/default/'},
                {tit:'加入露晰得',path:'/about'},
                {tit:'联系我们',path:'/contact'}
            ]
          }
      },
      mounted(){
        console.log(this.$route.path)
      },
      methods:{
        toPath(item){
          if(item.tit!='露晰得韩国'){
                if(item.path==this.$route.path) return
                this.$router.push(item.path)
            }else{
                window.location.href=item.path   
            }
        },
        menClick(data){
           if(data.id==7){
                this.$router.push('/about')
           }else{
                this.$router.push('/home?md='+data.id)
           }
        }
      }
  
  }
  </script>
  
  <style scoped lang="less">
.t-28{
    color: #333333;
    font-size: 28px;
  }
  .t-24{
    color: #333333;
    font-size: 24px;
  }
  
  .t-14{
    color: #333333;
    font-size: 14px;
  }
  
  .t-18{
    color: #333333;
    font-size: 18px;
  }
  .mb10{
    margin-bottom: 10px;
  }
.flex{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .flex-left{
        text-align: left;
    }
    .add{
        width: 530x;
        height: 340px;
    }

}
.avtive{
    color: #c2c2c2 !important;
  }
  .avtive:hover{
        color: #124fa0 !important;
    }
  .p-120{
    color: rgb(51, 51, 51);
    // font-family: Geneva, Arial, Helvetica, sans-serif;
    font-size: 14px;
    padding: 30px 120px;
    text-align: left;
    box-sizing: border-box;
    white-space: normal;
    box-sizing: border-box
  }
  .about-img{
    width: 1519px; height: 350px
  }
  
  .width{
      width: 1170px;
      margin: 0 auto;
  }
  .pad{
    width: 600px;
    margin: 0 auto;
    height: 45px;
    display: flex;
    align-items: center;
    .pa-item{
        width: 25%;
        text-align: center;
        font-size: 12px;
        cursor: pointer;
        color: #FFFFFF;
    }
    .pa-item:hover{
        color: #4E9AF7;
    }
  }
  .men{
    margin-top: 80px;
    background-color: #666666;
    height: 45px;
  }
  </style>