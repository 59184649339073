<template>
    <div class="home" >
          <!-- 头部 -->
          <Heard @menClick="menClick"/>
          <div class="men">
            <div class="pad">
                <div class="pa-item" :class="($route.path==item.path?'avtive':'')" @click="toPath(item)" v-for="item in list" :key="item.tit">{{item.tit}}</div>
            </div>
          </div>
          <img  class="about-img" src="https://www.lucidkorea.com/upload/editor/images/2021/01/22/192659721.jpg" alt="">
          <div class="width p-120">
            <div class="t-28">有一个机会，成为有康伙伴</div>
            <div class="t-18">共创未来无限可能</div>
            <div class="t-14">UCARE GROUP，矢志于助推和引领中国眼视光的发展，成为一家受人尊敬的公司！旗下拥有高端连锁眼科门诊、眼视光产品服务机构等多个平台！</div>
            <div class="t-14">在这里，意味着一个获得超越简单雇佣关系的机会，成为我们的伙伴。</div>
            <div class="t-14">在这里，我们称彼此为伙伴，让每一天都充满意义地连接彼此，实现更多、成就更多，并且成为最好的自己。更有机会成为UCARE PARTNER合伙人</div>
            <div class="t-14">计划的一员！</div>
            <div class="t-14">在这里，我们一起成长，创造无限可能。UCARE GROUP的发展速度，取决于人才的成长速度！</div>
            <div class="t-14">UCARE GROUP，一个以眼视光为边际、以天空为极限的舞台！</div>
            <div class="t-14">期待你的加入！</div>
            <div class="t-28">全面薪酬福利制度</div>
            <div class="t-14">我们的伙伴可以享受一系列的薪酬福利项目。根据工作职位和个人情况的不同，伙伴的全面薪酬福利包括：</div>
            <div class="t-14">管理培训计划 “家关怀”计划</div>
            <div class="t-14">年度旅游计划 年终绩效奖金</div>
            <div class="t-14">员工父母体检 宿舍交通补贴</div>
            <div class="t-14">Thinking Day Happy Day</div>
            <div class="t-14">UCARE PARTNER合伙人计划</div>
            <div class="t-28">未来，为你而来！</div>
             <div class="con-p m-b-30"></div>
             <div class="t-18 tem40">省区经理</div>
             <div class="t-14" style="font-weight: bold;margin-bottom: 10px;">我们将优先根据您所在区域，安排相应负责的省区。</div>
             <div class="t-14 mb-10">岗位职责：</div>
             <div class="t-14">1、负责贯彻落实公司渠道策略、政策和计划；</div>
             <div class="t-14">2、负责对行业市场的目标客户进行攻关，并协助经销商进行市场开拓；</div>
             <div class="t-14">3、负责收集、分析、整理、归档客户需求、竞争对手等市场信息；</div>
             <div class="t-14">4、负责经销商和重点医院客户的业务接洽、咨询及关系维护；；</div>
             <div class="t-14">5、负责区域销售计划的分解、落实，并进行跟踪与评估；</div>
             <div class="t-14">6、负责对发展滞后的市场提出整改意见并监督实施；</div>
             <div class="t-14">7、协助财务部做好经销商的信用管理工作；</div>
             <div style="height:40px"></div>
             <div class="t-14 mb-10">任职要求：</div>
             <div class="t-14"> 1、大专学历，对眼视光行业有浓厚兴趣及意愿，致力于把眼视光行业作为事业发展方向；</div>
             <div class="t-14">2、具有二年以上角膜塑形镜或隐形眼镜产品销售和渠道管理经验优先；</div>
             <div class="t-14">3、具有良好的人际沟通、协调能力，分析及解决问题的能力；</div>
             <div class="t-14">4、工作严谨，坦诚正直，工作计划性强，并具有战略前瞻性思维；</div>
             <div class="t-14">5、写作能力强，熟悉日常办公软件操作，具有较高的工作效率；</div>
             <div class="t-14">6、有较强的事业心，具有团队配合性，服从直属领导工作安排；</div>
             <div class="t-14">7、善思善学，能适应公司快速发展的创新文化；</div>
             <div class="t-14">8、具有较强的抗压能力，敢于挑战自我。</div>
             <div class="con-p m-t-30 m-b-30"></div>






             <div class="t-18 tem40">技术支持（眼视光医学）</div>
             <div class="t-14" style="font-weight: bold;margin-bottom: 10px;">我们将优先根据您所在区域，安排相应负责的省区。</div>
             <div class="t-14 mb-10">岗位职责：</div>
             <div class="t-14">1、协助全国渠道市场内角膜塑形镜验配支持及教摘戴；</div>
             <div class="t-14">2、负责日常培训的组织、管理及授课，并与医院等外联相关机构建立良好的合作关系；</div>
             <div class="t-14">3、负责建立、完善产品技术资料及培训资料；</div>
             <div class="t-14">4、负责客户应用信息的收集、汇总并提出有效的培训改善意见；</div>
             <div class="t-14">5、根据培训效果评估，设计相匹配的培训课程；</div>
             <div class="t-14">6、配合做好各类市场活动的跟进与支持；</div>
             <div class="t-14">7、上级交代的其他工作。</div>
             <div style="height:40px"></div>
             <div class="t-14 mb-10">任职要求：</div>
             <div class="t-14">1、视光专业大专及以上学历；</div>
             <div class="t-14">2、独立验配角膜塑形镜，有验配露晰得角膜塑形镜工作经验者优先；</div>
             <div class="t-14">3、具备一定带教能力，能带教视光师验配露晰得角膜塑形镜；</div>
             <div class="t-14">4、具备良好的沟通表达、演讲能力；</div>
             <div class="t-14">5、具备良好的团队意识；</div>
             <div class="t-14">6、能承担压力，能适应出差。</div>
             <div class="con-p m-t-30 m-b-30"></div>
             <div class="t-18 tem40">眼科/眼视光医师</div>
             <div class="t-14 mb-10">岗位职责：</div>
             <div class="t-14">1、负责包括眼底镜检查，眼底镜报告解读，眼科常见疾病诊断及治疗等；</div>
             <div class="t-14">2、借助专业性医疗设备、仪器测定眼视力程度及眼器官各组织的功能状态；</div>
             <div class="t-14">3、运用国内外先进医疗实践开展临床相关诊疗，提高医疗质量；</div>
             <div class="t-14">4、制定详细验光配镜，矫正视力治疗计划，做好病历和处方的规范书写；</div>
             <div class="t-14">5、注重健康良好的医患关系，注意保护病人诊疗信息隐私，尊重病人的权利；</div>
             <div class="t-14">6、协助和配合市场部做好相关的市场推广和宣传活动。积极配合门诊医疗负责人做好其他工作；</div>
             <div class="t-14">7、执业行为严格遵守《国家执业医师法》，能接受服务行业的工作时间、工作性质。</div>
             <div style="height:40px"></div>
             <div class="t-14 mb-10">任职要求：</div>
             <div class="t-14"> 1、眼科、临床医学及相关专业本科及以上学历，具备执业医师资格；</div>
             <div class="t-14">2、两年以上眼科工作经验，三级甲等或高端私家医院工作经验优先；</div>
             <div class="t-14">3、熟悉眼科专业知识理论，诊断学、医学影像学、实验室检查等相关专业知识技术；</div>
             <div class="t-14">4、较系统的掌握眼科常见病及多发病的诊断知识；</div>
             <div class="t-14">5、掌握医疗管理知识，熟悉相关业务流程，熟悉计算机等办公设备的应用知识；</div>
             <div class="t-14">6、有较强的工作责任心和服务意识；</div>
             <div class="t-14">7、具有良好的人际沟通能力、较强的计划制定和执行能力。</div>
             <div class="con-p m-t-30 m-b-30"></div>
             <div class="t-18 tem40">验配师/验光师</div>
             <!-- <div class="t-14" style="font-weight: bold;margin-bottom: 10px;">我们将优先根据您所在区域，安排相应负责的省区。</div> -->
             <div class="t-14 mb-10">岗位职责：</div>
             <div class="t-14">1、负责派驻医院眼科的验光、配镜工作，完成公司下达的销售目标；</div>
             <div class="t-14">2、负责协助眼科进行客户接待、初诊问询相关工作；</div>
             <div class="t-14">3、负责协助眼科医生诊疗相关支持工作。</div>
             <div style="height:40px"></div>
             <div class="t-14 mb-10">任职要求：</div>
             <div class="t-14"> 1、大专及以上学历，眼视光或相关专业，有眼视光或者眼镜行业工作经验优先；</div>
             <div class="t-14">2、有1年以上工作经验，或是具备比较扎实的眼视光及相关专业知识的优秀应届毕业生；</div>
             <div class="t-14">3、有验光员及以上资质；</div>
             <div class="t-14">4、精通眼视光相关仪器及设备的操作；</div>
             <div class="t-14">5、具备优秀的沟通表达能力；</div>
             <div class="t-14">6、勇于承担压力，积极主动，具备良好的团队精神。</div>

            <a class="gwto" href="https://jobs.51job.com/all/co4696450.html?timestamp__1258=n4fx07G%3DoGqxgDBuex2mDy7Dn7zaIvm%3D4x&alichlgref=https%3A%2F%2Fwww.lucidkorea.com%2F">更多招聘职位</a>
            <div class="t-18 mb-10" style="textAlign:left">我们在这里等你！</div>
            <div class="t-14 mb-10">可将简历投递到有康HR：hrm@ucaresh.com</div>
            <div class="t-14 mb-10">或拨打总机：021-58305301 / 5302 / 5390 / 5393</div>
            <div style="height:60px"></div>
          </div>
          <bottom />
    </div>
  </template>
  
  <script>
  import Heard from '../../components/heard.vue'
  import Bottom from '@/components/bottom.vue'
  export default {
      name:'Product',
      components:{ Heard,Bottom},
      data(){
          return{
            list:[
                {tit:'露晰得中国',path:'/china'},
                {tit:'露晰得韩国',path:'https://www.lucid.co.kr/default/'},
                {tit:'加入露晰得',path:'/about'},
                {tit:'联系我们',path:'/contact'}
            ]
          }
      },
      mounted(){
        console.log(this.$route.path)
      },
      methods:{
        menClick(data){
           if(data.id==7){
                this.$router.push('/about')
           }else{
                this.$router.push('/home?md='+data.id)
           }
        },
        toPath(item){
            if(item.tit!='露晰得韩国'){
                if(item.path==this.$route.path) return
                this.$router.push(item.path)
            }else{
                window.location.href=item.path   
            }
        },
      }
  
  }
  </script>
  
  <style scoped lang="less">
  .gwto{
    display: block;
    text-align: left;
    font-size: 18px;
    color: rgb(79, 129, 189);
    text-decoration: underline;
    margin: 30px auto 70px;
  }
  .mb-10{
    margin-bottom: 8px !important;
  }
  .m-t-30{
    margin-top: 36px !important;
  }
  .m-b-30{
    margin-bottom: 36px !important;
  }
  .con-p{
    background: rgb(194, 194, 194);
    margin: 10px;
    padding: 0px;
    width: 930px;
    height: 1px;
    box-sizing: border-box;
  }
  .t-28{
    font-size: 28px;
    color: #333333;
    margin-bottom: 16px;
    margin-top: 20px;
  }
  .t-14{
    font-size: 14px;
    color: #333333;
    text-align: left;
    margin-bottom: 4px;
  }
  .t-18{
    font-size: 18px;
    color: #333333;
    margin-bottom: 4px;
}
.tem40{
    text-align: left;
    margin-bottom: 36px;
}
  .p-120{
    color: rgb(51, 51, 51);
    // font-family: Geneva, Arial, Helvetica, sans-serif;
    font-size: 14px;
    padding: 0 120px;
    box-sizing: border-box;
    white-space: normal;
    box-sizing: border-box
  }
  .about-img{
    width: 100%;
    // height: 518px;
  }
  .avtive{
    color: #c2c2c2 !important;
  }
  .avtive:hover{
        color: #124fa0 !important;
    }
  
  .width{
      width: 1170px;
      margin: 0 auto;
  }
  .pad{
    width: 600px;
    margin: 0 auto;
    height: 45px;
    display: flex;
    align-items: center;
    .pa-item{
        width: 25%;
        text-align: center;
        font-size: 12px;
        cursor: pointer;
        color: #FFFFFF;
    }
    .pa-item:hover{
        color: #4E9AF7;
    }
  }
  .men{
    margin-top: 80px;
    background-color: #666666;
    height: 45px;
  }
  </style>